import type { ClinicOrderKey } from '~/typeEntities/stores/Clinic'
import type { OrderDirection } from '~/typeEntities/utilities/OrderDirection'
export const useSearchStore = defineStore('search-store', () => {
  const prefectureStore = usePrefecturesModalStore()
  const tcStore = useTreatmentCategoryModalStore()
  const listSubjectIds = ref([] as number[])
  const conditionStore = useTreatmentStore()
  const clinicListStore = useClinicListStore()
  const listSearchTagStore = useListSearchTagStore()

  const facilityTypeIds = ref([] as number[])
  const stationIds = ref([] as number[])
  const searchMode = ref('clinic' as 'clinic' | 'menu')

  const orderOption = ref<{ key: ClinicOrderKey; order: OrderDirection }>({
    key: 'totalWeight',
    order: 'desc',
  })

  const setOptions = (
    {
      facilityTypeIdArr = [],
      prefecturesObject = {},
      treatmentObject = {},
      conditionIds = [],
      stationIdArr = [],
      listSubjectIdArr = [],
    }: {
      facilityTypeIdArr?: number[]
      stationIdArr?: number[]
      prefecturesObject?: { [key: number]: number[] }
      treatmentObject?: { [key: number]: number[] }
      conditionIds?: number[]
      listSubjectIdArr?: number[]
    },
    pageVal = 1
  ) => {
    clinicListStore.currentPage = pageVal
    facilityTypeIds.value = facilityTypeIdArr
    listSubjectIds.value = listSubjectIdArr
    stationIds.value = stationIdArr
    setPrefectures(prefecturesObject)
    setTreatmentCategories(treatmentObject)

    conditionStore.setSelectedOptions(conditionIds)
  }
  const setOrder = (key: ClinicOrderKey) => {
    orderOption.value.key = key
  }
  const setPage = (pageVal: number) => {
    clinicListStore.currentPage = pageVal
  }
  const setMenuPage = (page: number) => {
    clinicListStore.currentMenuPage = page
  }
  const setSearchMode = (mode: 'clinic' | 'menu') => {
    searchMode.value = mode
  }
  const flushClinicList = () => {
    clinicListStore.setClinics([])
    clinicListStore.setPageData(1, 1, 0)
  }
  const fetchConditions = () => {
    return conditionStore.fetchConditionsByTreatmentIds(
      tcStore.getSelectedIds()
    )
  }

  const fetchClinicList = async () => {
    const { data } = await useFetch('/api/v1/stores/search', {
      params: {
        ...getParams(),
        page: clinicListStore.currentPage,
        orderKey: orderOption.value.key,
        orderDirection: orderOption.value.order,
      },
    })
    return data.value
  }

  const fetchListStats = async () => {
    const { data } = await useFetch('/api/v1/stores/searchStats', {
      params: { ...getParams() },
    })
    return data.value
  }

  const setListSearchTagStoreStats = () => {
    const paramsObject = prefectureStore.getParamsObject
    if (stationIds.value.length > 0) {
      listSearchTagStore.setArea(
        stationIds.value.map((id) => ({ id, areaType: 'Station' }))
      )
    } else if (paramsObject.sectionIds.length > 0) {
      listSearchTagStore.setArea(
        paramsObject.sectionIds.map((id) => ({ id, areaType: 'Section' }))
      )
    } else if (paramsObject.prefectureIds.length > 0) {
      listSearchTagStore.setArea(
        paramsObject.prefectureIds.map((id) => ({ id, areaType: 'Prefecture' }))
      )
    } else {
      listSearchTagStore.setArea([])
    }

    listSearchTagStore.setTreatment(tcStore.getSelectedIds())
  }

  const fetchAndSetClinicList = async () => {
    const data = await fetchClinicList()
    if (!data) return
    clinicListStore.setClinics(data.clinics || [], resortByTotalWeight.value)
    const { currentPage, pageCount, count } = data
    clinicListStore.setPageData(currentPage, pageCount, count)
    setListSearchTagStoreStats()
  }

  const fetchAndAddNextPageClinics = async () => {
    clinicListStore.currentPage++
    const data = await fetchClinicList()

    if (!data) return

    clinicListStore.addClinics(data.clinics || [], resortByTotalWeight.value)
    const { currentPage, pageCount, count } = data
    clinicListStore.setPageData(currentPage, pageCount, count)
  }

  const fetchAndSetClinicsOfPage = async (page: number) => {
    clinicListStore.currentPage = page
    const data = await fetchClinicList()
    if (!data) return
    clinicListStore.setClinics(data.clinics || [], resortByTotalWeight.value)
    const { currentPage, pageCount, count } = data
    clinicListStore.setPageData(currentPage, pageCount, count)
    setListSearchTagStoreStats()
  }

  const setPrefectures = (prefecturesObject: { [key: number]: number[] }) => {
    const pKeysArray: number[] = Object.keys(prefecturesObject).map(Number)
    const prefOptions = {} as {
      [key: number]: {
        selectAll: boolean
        sectionIds: number[]
      }
    }
    pKeysArray.forEach((key) => {
      const sectionArray = prefecturesObject[key]
      if (sectionArray.length > 0) {
        prefOptions[key] = {
          selectAll: false,
          sectionIds: sectionArray,
        }
      } else {
        prefOptions[key] = {
          selectAll: true,
          sectionIds: [],
        }
      }
    })
    prefectureStore.setSelectedOptions(prefOptions)
  }
  const setPrefecturesByFlatIds = (
    prefectureIds: number[],
    sectionIds: number[]
  ) => {
    prefectureStore.setSelectedOptionsByFlatParams(prefectureIds, sectionIds)
  }

  const setOptionsByFlatIds = ({
    treatmentIds = [],
    prefectureIds = [],
    sectionIds = [],
    detailedConditionIds = [],
  }: {
    treatmentIds?: number[]
    prefectureIds?: number[]
    sectionIds?: number[]
    detailedConditionIds?: number[]
  }) => {
    prefectureStore.setSelectedOptionsByFlatParams(prefectureIds, sectionIds)
    tcStore.setSelectedOptionsByFlatParams(treatmentIds)
    conditionStore.setSelectedOptions(detailedConditionIds)
  }

  const setTreatmentCategories = (treatmentObject: {
    [key: number]: number[]
  }) => {
    const tKeysArray: number[] = Object.keys(treatmentObject).map(Number)
    const treatmentOptions = {} as {
      [key: number]: {
        treatmentIds: number[]
      }
    }
    tKeysArray.forEach((key) => {
      treatmentOptions[key] = {
        treatmentIds: treatmentObject[key],
      }
    })
    tcStore.setSelectedOptions(treatmentOptions)
  }

  const getFacilityParams = computed(() => {
    if (facilityTypeIds.value.length === 0) return {}

    return {
      facilityTypeIds: facilityTypeIds.value,
    }
  })
  const getListSubjectParams = computed(() => {
    if (listSubjectIds.value.length === 0) return {}

    return {
      listSubjectIds: listSubjectIds.value,
    }
  })
  const getStationParams = computed(() => {
    if (stationIds.value.length === 0) return {}

    return {
      stationIds: stationIds.value,
    }
  })

  const resortByTotalWeight = computed(() => {
    return (
      orderOption.value.key === 'totalWeight' && stationIds.value.length > 0
    )
  })

  const getParams = () => {
    return {
      ...getStationParams.value,
      ...getFacilityParams.value,
      ...getListSubjectParams.value,
      ...prefectureStore.getParamsObject,
      ...tcStore.getParamsObject,
    }
  }
  const getOrder = computed(() => {
    return orderOption.value
  })

  const isStationSelected = computed(() => {
    return stationIds.value.length > 0
  })

  return {
    searchMode,
    setOptions,
    setPage,
    setMenuPage,
    setOrder,
    setSearchMode,
    flushClinicList,
    fetchClinicList,
    fetchListStats,
    fetchAndAddNextPageClinics,
    fetchAndSetClinicsOfPage,
    fetchAndSetClinicList,
    setPrefecturesByFlatIds,
    setOptionsByFlatIds,
    getParams,
    getOrder,
    fetchConditions,
    isStationSelected,
  }
})
