import type { BreadCrumbReturn } from '~/typeEntities/stores/BreadCrumb'

type Area = {
  id: number
  areaType: 'Prefecture' | 'Section' | 'Station'
}
const prefectureTagCount = 8
const sectionTagCount = 16
export const useListSearchTagStore = defineStore(
  'list-search-tag-store',
  () => {
    const prefecturesStore = usePrefecturesStore()
    const treatmentStore = useTreatmentCategoryStore()
    const currentArea = ref([] as Area[])

    const currentTreatmentId = ref([] as number[])
    const setArea = (area: Area | Area[] | null) => {
      if (Array.isArray(area)) {
        currentArea.value = area
      } else if (area) {
        currentArea.value = [area]
      } else {
        currentArea.value = []
      }
    }
    const setTreatment = (treatmentId: number[] | number | null) => {
      if (Array.isArray(treatmentId)) {
        currentTreatmentId.value = treatmentId
      } else if (treatmentId) {
        currentTreatmentId.value = [treatmentId]
      } else {
        currentTreatmentId.value = []
      }
    }

    const tagType = computed((): 'None' | 'Prefecture' | 'Section' => {
      if (currentArea.value.length > 1) {
        return 'None'
      }
      if (currentArea.value.length === 0) {
        return 'Prefecture'
      }
      switch (currentArea.value[0].areaType) {
        case 'Prefecture':
          return 'Section'
        case 'Section':
        case 'Station':
          return 'None'
        default:
          return 'Prefecture'
      }
    })

    const getSearchTags = computed((): BreadCrumbReturn[][] => {
      const tags = (() => {
        switch (tagType.value) {
          case 'Prefecture':
            return getPrefecturesTag.value
          case 'Section': {
            const { prefectures } = prefecturesStore
            const area = currentArea.value[0]
            const prefecture = prefectures.find((p) => p.id === area.id)
            if (!prefecture) {
              return getPrefecturesTag.value
            }
            return (
              prefecture.sections.slice(0, sectionTagCount).map((s) => {
                return {
                  sourceType: 'Section',
                  sourceId: s.id,
                  sourceSlug: s.id.toString(),
                  sourceName: s.name,
                  indexTitle: s.name,
                }
              }) || getPrefecturesTag.value
            )
          }
          case 'None':
            return []
        }
      })()

      return [
        tags.slice(0, tags.length / 2),
        tags.slice(tags.length / 2, tags.length),
      ]
    })
    const isShowable = computed(() => {
      return getSearchTags.value[0].length > 0
    })
    const getPrefecturesTag = computed((): BreadCrumbReturn[] => {
      return prefecturesStore.prefectures
        .slice(0, prefectureTagCount)
        .map((p) => {
          return {
            sourceType: 'Prefecture',
            sourceId: p.id,
            sourceSlug: p.slug,
            sourceName: p.name,
            indexTitle: p.name,
          }
        })
    })
    const getTitle = computed(() => {
      switch (tagType.value) {
        case 'Prefecture':
          return 'エリアで絞り込む'
        case 'Section':
          return 'エリアで絞り込む'
        case 'None':
          return '絞り込む'
      }
    })
    const getSubTitle = computed(() => {
      switch (tagType.value) {
        case 'Prefecture':
        case 'Section': {
          const treatments = treatmentStore.getTreatment(
            currentTreatmentId.value
          )
          if (treatments.length === 0) return 'すべての施術'
          return treatments.map((t) => t.name).join(', ')
        }
        case 'None':
          return ''
      }
    })
    return {
      currentArea,
      setArea,
      setTreatment,
      getSearchTags,
      getTitle,
      isShowable,
      getSubTitle,
    }
  }
)
